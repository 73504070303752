import { __rest } from "tslib";
import { jsx as _jsx } from "react/jsx-runtime";
import cN from 'classnames';
import { startsWith } from 'lodash/fp';
import PropTypes from 'prop-types';
import * as React from 'react';
import history, { isPageReloadScheduled } from '../../../routing/history';
import { preventJavascriptUrls } from '../../../services/security';
import { trackEvent } from '../../services/Tracking';
import { prependAppPathToEmbbeddedWicketPages } from './service';
import styles from './style.m.less';
function safeDefaultRel(href, target, rel) {
    if (!!rel || target !== '_blank') {
        return rel;
    }
    try {
        const url = new URL(href);
        const linkToMarketingWebsite = url.hostname === 'www.small-improvements.com' || url.hostname === 'small-improvements.com';
        return linkToMarketingWebsite ? 'noopener' : 'noopener noreferrer';
    }
    catch (e) {
        return 'noopener noreferrer';
    }
}
class Link extends React.Component {
    constructor() {
        super(...arguments);
        this.handleClick = (e) => {
            const { onClick, openInNewTab } = this.props;
            const href = preventJavascriptUrls(prependAppPathToEmbbeddedWicketPages(this.props.href));
            if (onClick) {
                onClick(e);
            }
            if (!history ||
                e.ctrlKey ||
                e.metaKey ||
                e.shiftKey ||
                e.button !== 0 ||
                openInNewTab ||
                isPageReloadScheduled()) {
                return;
            }
            const isApp = startsWith('/app/', href);
            if (!isApp || e.isDefaultPrevented()) {
                return;
            }
            e.preventDefault();
            history.push(href.replace('/app', ''));
        };
    }
    render() {
        const _a = this.props, { href, title, children, className, style, target, rel, openInNewTab, hasTextStyle = true, eventTracking } = _a, passedProps = __rest(_a, ["href", "title", "children", "className", "style", "target", "rel", "openInNewTab", "hasTextStyle", "eventTracking"]);
        const t = openInNewTab ? '_blank' : target;
        const r = safeDefaultRel(href, t, rel);
        return (_jsx("a", Object.assign({}, passedProps, { href: preventJavascriptUrls(href), className: cN(className, { [styles.reset]: !hasTextStyle }), style: style, title: title, onClick: e => {
                if (eventTracking) {
                    trackEvent(eventTracking);
                }
                this.handleClick(e);
            }, target: t, rel: r, "data-react-link": true, children: children })));
    }
}
Link.propTypes = {
    title: PropTypes.string,
    href: PropTypes.string.isRequired,
    onClick: PropTypes.func,
    children: PropTypes.node.isRequired,
    openInNewTab: PropTypes.bool,
    hasTextStyle: PropTypes.bool,
};
export default Link;
