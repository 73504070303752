import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Plan } from '../../../../../../types/CompanyPaymentDto';
import * as R from '../../../../../routes';
import { getCycleLimit } from '../../../../services/ReviewCycles';
import { interpolateAppUrl } from '../../../../services/Url';
import Link from '../../../Link';
function getUpgradeLink() {
    return interpolateAppUrl(R.ADMIN_BILLING_CHECKOUT, {});
}
const REVIEW_CYCLE_OVERALL_RATING_PARSE_EXCEPTION = () => (_jsxs("div", { children: [_jsx("h1", { children: "Overall ratings unsupported" }), "You tried to create a cycle with an unsupported overall ratings configuration. We will investigate the issue, but it would help us if you could take a screenshot of your configuration. You can send it to", ' ', _jsx("a", { href: "mailto:support@small-improvements.com", children: "support@small-improvements.com" }), "."] }));
const PLAN_CYCLE_LIMIT_REACHED = () => {
    return (_jsxs("div", { children: [_jsx("h1", { children: "Cycle limit reached" }), "On your current plan, you can only create up to ", getCycleLimit(Plan.Launch), " cycles per year.", _jsx("br", {}), _jsx(Link, { href: getUpgradeLink(), children: "See Upgrade Options" })] }));
};
const FEEDBACK_PARTICIPANT_INACTIVE = () => (_jsxs("div", { children: [_jsx("h1", { children: "Failed to add user" }), "You tried to add an inactive reviewee to the cycle."] }));
const FEEDBACK_CYCLE_NOT_CURRENTLY_MATCHING = () => (_jsxs("div", { children: [_jsx("h1", { children: "Cycle creation failed" }), "The cycle is currently not in progress. Either it has not yet started or it is already over."] }));
export default {
    REVIEW_CYCLE_OVERALL_RATING_PARSE_EXCEPTION,
    PLAN_CYCLE_LIMIT_REACHED,
    FEEDBACK_PARTICIPANT_INACTIVE,
    FEEDBACK_CYCLE_NOT_CURRENTLY_MATCHING,
};
